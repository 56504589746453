








































import { Component, Vue } from "vue-property-decorator";
import { LocaleMessage } from "vue-i18n";
import router, { ViewName } from "@/router";
import { AxiosError } from "axios";
import { authModule } from "@/store/auth";
@Component({
  components: {},
})
export default class Login extends Vue {
  errorMessage: LocaleMessage | null = "";
  username = "";
  password = "";
  
  handleLogin() {
    this.errorMessage = "";
    const formData = new FormData();
    formData.append("username", this.username);
    formData.append("password", this.password);
    authModule
      .login({ formData: formData })
      .then(() => {
        router
          .push((new URLSearchParams(window.location.search)).get('redirect') ?? "/")
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      })
      .catch((error: AxiosError) => {
        //loginError
      });
  }
}
