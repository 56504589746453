












import { Vue, Component, Prop } from "vue-property-decorator";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/sv";

@Component({
  components: { DatePicker },
})
export default class Datepicker extends Vue {
  @Prop() value!: string;
  @Prop() disabled!: boolean;
}
