






































import { Vue, Component } from "vue-property-decorator";
import { usersModule } from "@/store/users";
import { User } from "@/types/users";
@Component({
  components: {},
})
export default class InfoBar extends Vue {
  get userDetails(): User | null {
    return usersModule.user;
  }
}
