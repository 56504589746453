






























































import { Vue, Component, Prop } from "vue-property-decorator";
import { ordersModule } from "@/store/orders";
import { Order } from "@/types/orders";
import Modal from "@/components/common/Modal.vue";
@Component({
  components: { Modal },
})
export default class ToolsBar extends Vue {
  @Prop({ required: true }) order!: Order;
  showModal = false;
  externalOrderFileURL = "/api/order/externalpdf/";
  markingsFileURL = "/api/order/markingspdf/";
  orderXMLURL = "/api/order/orderxml/";

  get isModalOpen(): boolean {
    return ordersModule.showModal;
  }

  toggleModal() {
    ordersModule.setShowModal(!this.isModalOpen);
  }

  get printIcon(): string {
    return require("@/assets/print.svg");
  }

  get externalOrderIcon(): string {
    return require("@/assets/externalorder.svg");
  }
}
