import "@/assets/styles/index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/styles/transitions.css";

import App from "./App.vue";
import BootstrapIconsPlugin from "bootstrap-icons-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LoadingSymbol from "@/components/common/LoadingSymbol.vue";
import ModalPlugin from "bootstrap-vue";
import Vue from "vue";
import VueEllipseProgress from "vue-ellipse-progress";
import { fas } from "@fortawesome/free-solid-svg-icons";
import i18n from "./i18n";
import { library } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import router from "./router";
import store from "./store";

Vue.use(ModalPlugin);

Vue.config.productionTip = false;

Vue.filter("formatDate", function (value: Date) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD");
  }
});
library.add(fas);
Vue.use(VueEllipseProgress);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("BootstrapIconsPlugin", BootstrapIconsPlugin);
Vue.component("LoadingSymbol", LoadingSymbol);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
