




import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class LoadingSymbol extends Vue {
  @Prop({ default: "lg" }) size: string | undefined;
}
