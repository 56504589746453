export interface Order {
  id: number;
  offer: number;
  orderNo: string;
  desiredDelivery: Date | null;
  status: number;
  trackingId: string;
  trackingIds: string[];
  freightCompany: string;
  freightCost: number;
  distributionCost: number;
  packagingCost: number;
  printFiles: string;
  comments: string;
  shippedDate: Date | null;
  plannedShipmentDate: Date | null;
  completedDate: Date | null;
  acceptedDate: Date | null;
  deliveryType: string;
  orderAccepted: boolean;
  orderCompleted: boolean;
  incoming: Date | null;
  supplierName: string;
}

export interface OrderFile {
  name: string;
  downloadLink: string;
}

export interface FreightCompany {
  id: number;
  name: string;
}

export enum OrderStatus {
  ALL = -1,
  NEW = 0,
  ACCEPTED = 1,
  PLANNED = 2,
  DELIVERED = 3,
  COMPLETED = 4,
}

export enum OrderColor {
  NEW = "#da1217",
  ACCEPTED = "#f98300",
  PLANNED = "#ffd100",
  DELIVERED = "#0066d4",
  COMPLETED = "#2ac371",
}
