import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";

import { User } from "@/types/users";
import store from "@/store";
import { userApi } from "@/api";

@Module({ dynamic: true, store, name: "UsersStore", namespaced: true })
export default class UsersStore extends VuexModule {
  user: User | null = null;

  get currentUser(): User | null {
    return this.user;
  }

  @Mutation
  setUser(user: User) {
    this.user = user;
  }

  @Action
  async fetchUser(): Promise<User> {
    return userApi.getUser().then((user: User) => {
      this.setUser(user);
      return user;
    });
  }
}

export const usersModule = getModule(UsersStore, store);
