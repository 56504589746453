
















import { Vue, Component, Prop } from "vue-property-decorator";
import OrderBox from "@/components/OrderBox.vue";
import { LocaleMessage } from "vue-i18n";
import { ordersModule } from "@/store/orders";
import { Order, OrderStatus } from "@/types/orders";
import { authModule } from "@/store/auth";
@Component({
  components: { OrderBox },
})
export default class OrderList extends Vue {
  @Prop({ required: true }) orders!: Order[];
  errorMessage: LocaleMessage | null = "";

  get isAdmin(): boolean {
    return authModule.authCompany === "Storbildsfabriken i Sverige AB"
      ? true
      : false;
  }

  get filterValue(): number {
    return ordersModule.filterValue;
  }

  get filteredOrders(): Order[] {
    return this.orders.filter((order) => this.filter(order));
  }

  filter(order: Order): boolean {
    if (this.filterValue == OrderStatus.ALL) {
      return true;
    }
    if (this.filterValue == OrderStatus.COMPLETED) {
      return order.orderCompleted;
    }
    const byStatus = order.status === this.filterValue;
    return byStatus;
  }
}
