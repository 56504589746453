













import { Vue, Component } from "vue-property-decorator";
import MenuBar from "@/components/MenuBar.vue";
import { authModule } from "@/store/auth";

@Component({
  components: { MenuBar },
})
export default class TopBar extends Vue {
  get isAuthenticated(): boolean {
    return !!authModule.authToken;
  }
}
