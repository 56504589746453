









import { Vue, Component } from "vue-property-decorator";
import { authModule } from "@/store/auth";
import CompanyInfo from "@/components/CompanyInfo.vue";
@Component({
  components: { CompanyInfo },
})
export default class InfoBar extends Vue {
  get isAuthenticated(): boolean {
    return !!authModule.authToken;
  }
}
