import { AxiosError, AxiosResponse } from "axios";
import { SharepointDownloadItem, SharepointItem } from "@/types/sharepointItem";

import { Api } from "@/api/axios";
import { Order } from "@/types/orders";
import { OrderFile } from "@/types/orders";
import { authModule } from "@/store/auth";

interface OrderResource {
  id: number;
  offer: number;
  orderNo: string;
  desiredDelivery: Date | null;
  status: number;
  trackingId: string;
  trackingIds: string[];
  freightCompany: string;
  freightCost: number;
  distributionCost: number;
  packagingCost: number;
  printFiles: string;
  comments: string;
  shippedDate: Date | null;
  plannedShipmentDate: Date | null;
  completedDate: Date | null;
  acceptedDate: Date | null;
  deliveryType: string;
  orderAccepted: boolean;
  orderCompleted: boolean;
  incoming: Date | null;
  supplierName: string;
}

interface OrderFileResource {
  name: string;
  downloadLink: string;
}

export default class OrderApi extends Api {
  ordersEndpointUrl: string;
  constructor(baseUrl: string | undefined, teamsEndpointUrl: string) {
    super();
    this.ordersEndpointUrl = baseUrl + teamsEndpointUrl;
    this.getAllOrders = this.getAllOrders.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.getSharepointFiles = this.getSharepointFiles.bind(this);
  }

  /**
   * Returns specific number of orders for a specific status
   * @returns {Promise<Order[]>} Promise<Order[]> - List of orders
   */
  public getAllOrders(): Promise<Order[]> {
    return this.get<OrderResource[]>("/api/order", {
      //return this.get<OrderResource[]>("https://sbf.dev.ucsit.se/api/order", {
      //return this.get<OrderResource[]>("https://localhost:7214/api/order", {
      headers: {
        authorization: authModule.authToken,
      },
    })
      .then((response: AxiosResponse<OrderResource[]>) => {
        const { data } = response;
        const state: Order[] = data;
        return state;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Updates and returns the specific order
   * @param {FormData} data - the order data
   * @returns {Promise<Order>} Promise<Order[]> - List of orders
   */
  public updateOrder(data: FormData): Promise<Order> {
    return this.post<OrderResource>(
      "/api/order",
      //"https://sbf.dev.ucsit.se/api/order",
      //"https://localhost:7214/api/order",
      data,
      {
        headers: {
          authorization: authModule.authToken,
        },
      }
    )
      .then((response: AxiosResponse<OrderResource>) => {
        const { data } = response;
        const state: Order = data;
        return state;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  /**
   * Returns all sharepoint files names
   * @returns {Promise<Order[]>} Promise<OrderFile[]> - List of order files
   */
  public getSharepointFiles(order: Order): Promise<SharepointItem[]> {
    return this.get<SharepointItem[]>(
      "/api/sharepoint/itemsInDirectory?directory=" +
        //"https://sbf.dev.ucsit.se/api/sharepoint/itemsInDirectory?directory=" +
        //"https://localhost:7214/api/sharepoint/itemsInDirectory?directory=" +
        order.printFiles.replaceAll("\\", "%5C"),
      {
        headers: {
          authorization: authModule.authToken,
        },
      }
    )
      .then((response: AxiosResponse<SharepointItem[]>) => {
        const { data } = response;
        const state: SharepointItem[] = data;
        return state;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  //  //api/order/getprintfile/{orderNo}/{company.Id}/{item.Name}"
  /**
   * Returns all sharepoint files names
   * @returns {Promise<Order[]>} Promise<OrderFile[]> - List of order files
   */
  public downloadSharepointFile(
    order: Order,
    itemId: string
  ): Promise<SharepointDownloadItem> {
    return this.get<SharepointDownloadItem>(
      "/api/sharepoint/download?directory=" +
        //"https://sbf.dev.ucsit.se/api/sharepoint/download?directory=" +
        //"https://localhost:7214/api/sharepoint/download?directory=" +
        order.printFiles.replaceAll("\\", "%5C") +
        "&itemId=" +
        itemId,
      {
        headers: {
          authorization: authModule.authToken,
        },
      }
    )
      .then((response: AxiosResponse<SharepointDownloadItem>) => {
        const { data } = response;

        const state: SharepointDownloadItem = data;
        return state;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
