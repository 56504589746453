












import { Vue, Component } from "vue-property-decorator";
import TopBar from "@/components/TopBar.vue";
import InfoBar from "@/components/InfoBar.vue";
import MenuBar from "@/components/MenuBar.vue";
import { ordersModule } from "./store/orders";

@Component({
  components: {
    TopBar,
    InfoBar,
    MenuBar,
  },
})
export default class App extends Vue {
  filterMe(filter: number) {
    ordersModule.setFilterValue(filter);
  }
}
