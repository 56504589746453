import VueI18n, { LocaleMessages } from "vue-i18n";

import Vue from "vue";

Vue.use(VueI18n);

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
});

export enum Locale {
  ENGLISH = "en",
  SWEDISH = "sv",
}
