import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import { FreightCompany, Order } from "@/types/orders";
import { SharepointDownloadItem, SharepointItem } from "@/types/sharepointItem";

import { AxiosError } from "axios";
import Vue from "vue";
import { orderApi } from "@/api";
import store from "@/store";

@Module({ dynamic: true, store, name: "orders", namespaced: true })
export default class OrdersStore extends VuexModule {
  orderList: Order[] = [];
  sharepointFileList: SharepointItem[] = [];
  orderFilter = -1;
  hasSharepointFiles = true;
  hasDownloadFile = true;
  showModal = false;

  get orders(): Order[] {
    return this.orderList;
  }

  get sharepointFiles(): SharepointItem[] {
    return this.sharepointFileList;
  }

  get filterValue(): number {
    return this.orderFilter;
  }

  get freighCompanyList(): FreightCompany[] {
    return [
      {
        id: 1,
        name: "SBF Agreement",
      },
      {
        id: 2,
        name: "Other",
      },
    ];
  }

  get statushasSharepointFiles(): boolean {
    return this.hasSharepointFiles;
  }

  get statushasDownloadFile(): boolean {
    return this.hasDownloadFile;
  }

  get isModalOpen(): boolean {
    return this.showModal;
  }

  @Mutation
  setHasSharepointFiles(hasSharepointFiles: boolean) {
    this.hasSharepointFiles = hasSharepointFiles;
  }

  @Mutation
  setHasDownloadFile(hasDownloadFile: boolean) {
    this.hasDownloadFile = hasDownloadFile;
  }

  @Mutation
  setShowModal(showModal: boolean) {
    this.showModal = showModal;
  }

  @Mutation
  setFilterValue(filter: number) {
    this.orderFilter = filter;
  }

  @Mutation
  resetStore() {
    this.orderList = [];
  }

  @Mutation
  setOrderList(orderList: Order[]) {
    this.orderList = orderList;
  }

  @Mutation
  setSharepointFileList(sharepointFileList: SharepointItem[]) {
    this.sharepointFileList = sharepointFileList;
  }

  @Mutation
  setOrder(order: Order) {
    Vue.set(
      this.orderList,
      this.orderList.findIndex((o) => o.id === order.id),
      order
    );
  }

  @Action
  async fetchAllOrders(): Promise<Order[]> {
    return orderApi.getAllOrders().then((orders: Order[]) => {
      this.resetStore();
      this.setOrderList(orders);
      return orders;
    });
  }

  @Action
  async updateOrder({ formData }: { formData: FormData }): Promise<Order> {
    return orderApi.updateOrder(formData).then((order: Order) => {
      this.setOrder(order);
      return order;
    });
  }

  @Action({ rawError: true })
  async fetchAllSharepointFiles(order: Order): Promise<SharepointItem[]> {
    return orderApi
      .getSharepointFiles(order)
      .then((fileNames: SharepointItem[]) => {
        this.setSharepointFileList(fileNames);
        return fileNames;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }

  @Action({ rawError: true })
  async downloadSharepointFile({
    order,
    itemId,
  }: {
    order: Order;
    itemId: string;
  }) {
    return orderApi
      .downloadSharepointFile(order, itemId)
      .then((url: SharepointDownloadItem) => {
        return url;
      });
  }
}

export const ordersModule = getModule(OrdersStore, store);
