






















































import { Vue, Component } from "vue-property-decorator";
@Component({
  components: {},
})
export default class OrderRowHeader extends Vue {
  getOrders() {
    location.reload();
  }
}
