import VueRouter, { RouteConfig } from "vue-router";

import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Vue from "vue";
import store from "@/store/index";

Vue.use(VueRouter);

export enum ViewName {
  Home = "Home",
  UserLogin = "Login",
  UserPasswordReset = "PasswordReset",
  UserPasswordSet = "PasswordSet",
  UserProfile = "Profile",
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: ViewName.Home,
    component: Home,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/login",
    name: ViewName.UserLogin,
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in. If not, redirect to login.
    if (!store.getters.isLoggedIn) {
      next({
        path: `/${ViewName.UserLogin}`,
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    // If we are authenticated and attempts to visit the login page.
    if (to.name === ViewName.UserLogin && store.getters.isLoggedIn) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  }
});
export default router;
