






import { Vue, Component, Prop } from "vue-property-decorator";
import OrderContent from "@/components/OrderContent.vue";
import { Order } from "@/types/orders";
@Component({
  components: { OrderContent },
})
export default class OrderBox extends Vue {
  @Prop({ required: true }) order!: Order;
  @Prop({ required: true }) isAdmin!: boolean;
}
