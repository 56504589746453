



































import { Vue, Component } from "vue-property-decorator";
import OrderRowHeader from "@/components/OrderRowHeader.vue";
import { ordersModule } from "@/store/orders";
import { usersModule } from "@/store/users";
import OrderList from "@/components/OrderList.vue";
import { Order } from "@/types/orders";
import { LocaleMessage } from "vue-i18n";
import moment from "moment";
import { AxiosError } from "axios";
@Component({
  components: { OrderRowHeader, OrderList },
})
export default class Home extends Vue {
  errorMessage: LocaleMessage | null = "";
  searchText = "";

  get orders(): Order[] {
    return ordersModule.orderList;
  }

  get searchResultOrders(): Order[] {
    return this.orders.filter((order) => this.search(order));
  }

  beforeMount() {
    this.fetchAllOrders();
    let orderNo = window.location.hash;
    if (orderNo) {
      this.searchText = orderNo.replace('#', '');
    }
  }

  fetchAllOrders() {
    ordersModule
      .fetchAllOrders()
      .then(() => {
        usersModule
          .fetchUser()
          .then(() => {
            //
          })
          .catch((error: AxiosError) => {
            //routerError
          });
      })
      .catch((error: AxiosError) => {
        //fetchAllOrdersError
      });
  }

  search(order: Order): boolean {
    var byDesiredDeliveryDate = false;
    const searchText = this.searchText.toLowerCase();
    const byOrderNo = order.orderNo.toLowerCase().indexOf(searchText) !== -1;
    if (order.desiredDelivery) {
      byDesiredDeliveryDate =
        this.admissionDateFormat(order.desiredDelivery)
          .toString()
          .indexOf(searchText) !== -1;
    }
    const bySupplierName =
      order.supplierName.toLowerCase().indexOf(searchText) !== -1;
    return byOrderNo || byDesiredDeliveryDate || bySupplierName;
  }

  admissionDateFormat(date: Date): string {
    return moment(date).format("YYYY-MM-DD");
  }
}
