import { AxiosError, AxiosResponse } from "axios";

import { Api } from "@/api/axios";
import { User } from "@/types/users";
import { authModule } from "@/store/auth";

interface UserResource {
  id: number;
  name: number;
  email: string;
  freightAgreement: string;
  address: string;
  zipCode: number;
  city: string;
  country: string;
}

export default class UserApi extends Api {
  ordersEndpointUrl: string;
  constructor(baseUrl: string | undefined, userEndpointUrl: string) {
    super();
    this.ordersEndpointUrl = baseUrl + userEndpointUrl;
    this.getUser = this.getUser.bind(this);
  }

  /**
   * Returns detail of user
   * @returns {Promise<User>} Promise<User> - user
   */
  public getUser(): Promise<User> {
    return this.get<UserResource>("/api/user/details", {
      //return this.get<UserResource>("https://sbf.dev.ucsit.se/api/user/details", {
      //return this.get<UserResource>("https://localhost:7214/api/user/details", {
      headers: {
        authorization: authModule.authToken,
      },
    })
      .then((response: AxiosResponse<UserResource>) => {
        const { data } = response;
        const state: User = data;
        return state;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
