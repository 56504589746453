





















































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { Order, OrderFile } from "@/types/orders";
import { SharepointItem, SharepointDownloadItem } from "@/types/sharepointItem";
import { ordersModule } from "@/store/orders";
import { AxiosError } from "axios";
import { LocaleMessage } from "vue-i18n";

@Component({})
export default class Modal extends Vue {
  @Prop({ required: true }) order!: Order;
  @Prop({ default: true }) clickToClose!: boolean;
  //orderFiles: OrderFile[] = [];
  sharepointFiles: SharepointItem[] = [];
  errorMessage: LocaleMessage | null = "";

  @Emit("modalClosed")
  closeModal() {
    return;
  }

  mounted() {
    this.getSharepintFiles(this.order);
  }

  get loadingFiles(): boolean {
    return ordersModule.statushasSharepointFiles;
  }

  get downloadFile(): boolean {
    return ordersModule.statushasDownloadFile;
  }

  getSharepintFiles(order: Order) {
    ordersModule.setHasSharepointFiles(false);
    ordersModule
      .fetchAllSharepointFiles(order)
      .then((fileNames: SharepointItem[]) => {
        this.sharepointFiles = fileNames;
      })
      .finally(() => {
        ordersModule.setHasSharepointFiles(true);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          this.errorMessage = "No files was found";
        }
      });
  }

  downloadSharepointFile(itemId: string) {
    ordersModule.setHasDownloadFile(false);
    ordersModule
      .downloadSharepointFile({ order: this.order, itemId: itemId })
      .then((url: SharepointDownloadItem) => {
        window.location.href = url.downloadLink;
      })
      .finally(() => {
        ordersModule.setHasDownloadFile(true);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          this.errorMessage = "No files was found";
        }
      });
  }
}
