import AuthApi from "./modules/authApi";
import OrderApi from "./modules/orderApi";
import Routes from "./routes";
import UserApi from "./modules/userApi";

const baseUrl = process.env.VUE_APP_SERVER_URL;

export const orderApi = new OrderApi(baseUrl, Routes.ORDERS);
export const authApi = new AuthApi(baseUrl, Routes.AUTH);
export const userApi = new UserApi(baseUrl, Routes.USER);
