















































import { authModule } from "@/store/auth";
import { Vue, Component, Emit } from "vue-property-decorator";
import { OrderStatus } from "@/types/orders";
@Component({
  components: {},
})
export default class MenuBar extends Vue {
  ALL = OrderStatus.ALL;
  NEW = OrderStatus.NEW;
  ACCEPTED = OrderStatus.ACCEPTED;
  PLANNED = OrderStatus.PLANNED;
  DELIVERED = OrderStatus.DELIVERED;
  COMPLETED = OrderStatus.COMPLETED;
  activeMenu = -1;

  get isAuthenticated(): boolean {
    return !!authModule.authToken;
  }

  @Emit("getfilter")
  getfilter(filter: number) {
    this.activeMenu = filter;
    return filter;
  }

  handleLogout() {
    authModule.logout();
  }
}
