import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import router, { ViewName } from "@/router";

import { AxiosError } from "axios";
import { LoginResponse } from "@/types/auth";
import { authApi } from "@/api";
import { ordersModule } from "./orders";
import store from "@/store";

@Module({ dynamic: true, store, name: "AuthStore" })
export default class AuthStore extends VuexModule {
  status = "";
  token = localStorage.getItem("token") || "";
  company = localStorage.getItem("company") || "";

  get authToken() {
    return this.token;
  }

  get authCompany() {
    return this.company;
  }

  get isLoggedIn() {
    return !!this.token;
  }

  @Mutation
  authSuccess({ token, company }: { token: string; company: string }) {
    this.status = "success";
    this.token = token;
    this.company = company;
  }

  @Mutation
  logoutAuth() {
    this.token = "";
    this.company = "";
    localStorage.removeItem("token");
    localStorage.removeItem("company");
    router.push({ name: ViewName.UserLogin }).catch(() => {
      //
    });
  }

  @Action
  async login({ formData }: { formData: FormData }) {
    return authApi
      .authenticate(formData)
      .then((response: LoginResponse) => {
        localStorage.setItem("token", response.token);
        localStorage.setItem("company", response.company);
        this.authSuccess({
          token: response.token,
          company: response.company,
        });
        return response;
      })
      .catch((error: AxiosError) => {
        localStorage.removeItem("token");
        throw error;
      });
  }

  @Action
  async logout() {
    this.logoutAuth();
    ordersModule.resetStore();
  }
}

export const authModule = getModule(AuthStore, store);
